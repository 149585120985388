import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra/dist/es/src/types/wix-sdk'
import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {DEFAULT_LANGUAGE, isLanguageBabelSupported} from '@wix/wix-events-commons-statics'

export const SERVER_PREFIX = '/_api/wix-one-events-server'

export const getPageInfo = async (wixCodeApi: IWixAPI) => {
  try {
    const siteStructure = await wixCodeApi.site.getSiteStructure()
    const homePage = siteStructure.pages.find(page => page?.isHomePage)
    return {isHomePage: homePage?.id === wixCodeApi.site.currentPage?.id}
  } catch (e) {
    console.log(e)
    return {isHomePage: false}
  }
}

export const isSSR = (wixCodeApi: IWixAPI) => wixCodeApi.window.rendering.env === 'backend'

export const getServerBaseUrl = (wixCodeApi: IWixAPI) => `${getBaseUrl(wixCodeApi)}${SERVER_PREFIX}`

export const getBaseUrl = (wixCodeApi: IWixAPI) => {
  if (isSSR(wixCodeApi)) {
    return parseBaseUrl(wixCodeApi).root
  } else {
    return ''
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

export const getMultilingualFullLocale = ({window: {multilingual}}: IWixAPI): string | undefined => {
  if (multilingual.isEnabled) {
    const siteLanguage = multilingual.siteLanguages.find(lang => lang.languageCode === multilingual.currentLanguage)
    return siteLanguage?.locale
  }
}

export const getLanguage = (wixCodeApi: IWixAPI) => {
  const currentLanguage = wixCodeApi.window?.multilingual?.currentLanguage
  const language = currentLanguage || wixCodeApi.site.language

  return isLanguageBabelSupported(language) ? language : DEFAULT_LANGUAGE
}

export const getViewMode = (wixCodeApi: IWixAPI) =>
  (wixCodeApi.window.viewMode === 'Site' ? 'Site' : 'Editor').toLowerCase() // preview loads wrong data for Editor

export const trackEvent = (wixCodeApi: IWixAPI, eventName: ITrackEventName, params: ITrackEventParams) =>
  wixCodeApi.window.trackEvent(eventName, params)
