import {AnyAction} from 'redux'
import {PaidPlansState} from '../types'
import {
  GET_PLAN_LIST,
  SET_PAID_PLANS_ENABLED,
  SET_SELECTED_PLAN_ORDER_ID,
  GET_PURCHASED_PLAN_LIST,
} from '../actions/paid-plans'
import {getSortedPlanList, getBestNonExpiredPlan} from '../selectors/paid-plans'

const defaultState: PaidPlansState = {
  enabled: false,
  planList: [],
  purchasedPlanList: [],
  selectedPaidPlanOrderId: null,
}

export const paidPlans = (state = defaultState, {type, payload}: AnyAction): PaidPlansState => {
  switch (type) {
    case SET_PAID_PLANS_ENABLED:
      return {...state, enabled: true}
    case GET_PLAN_LIST.SUCCESS: {
      const planList = getSortedPlanList(payload.plans)
      const purchasedPlanList = planList.filter(plan => plan.purchased)

      return {
        ...state,
        planList,
        purchasedPlanList,
        selectedPaidPlanOrderId: getBestNonExpiredPlan(purchasedPlanList)?.planOrderId ?? null,
      }
    }
    case GET_PURCHASED_PLAN_LIST.SUCCESS: {
      const purchasedPlanList = getSortedPlanList(payload.plans)

      return {
        ...state,
        purchasedPlanList,
        selectedPaidPlanOrderId: getBestNonExpiredPlan(purchasedPlanList)?.planOrderId ?? null,
      }
    }
    case SET_SELECTED_PLAN_ORDER_ID: {
      return {
        ...state,
        selectedPaidPlanOrderId: payload.planOrderId,
      }
    }
    default:
      return state
  }
}
