import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {EventStatus, EventType, RegistrationStatus, LocationType} from '@wix/wix-events-commons-statics'
import {State} from '../types'

export const renderSEOTags = async (controller: IWidgetControllerConfig, state: State, pageUrl: string) => {
  const itemData = getItemData(state, pageUrl)

  await controller.wixCodeApi.seo.renderSEOTags({
    itemType: 'EVENTS_PAGE',
    itemData,
  })
}

const getItemData = (state: State, pageUrl: string) => {
  return {
    event: transformEvent(state.event, pageUrl),
    tickets: state.tickets,
  }
}

const transformEvent = (event: wix.events.Event, pageUrl: string) => {
  return {
    ...event,
    status: EventStatus[event.status],
    eventPageUrl: {
      base: pageUrl,
      path: `/${event.slug}`,
    },
    registration: {
      ...event.registration,
      type: EventType[event.registration.type],
      status: RegistrationStatus[event.registration.status],
    },
    location: {
      ...event.location,
      type: LocationType[event.location.type],
    },
  }
}
