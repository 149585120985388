import {ExperimentsBag} from '@wix/wix-experiments'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const getExperiments = (state: CommonState) => state.experiments

export const isReactWidgetSettingsEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, 'specs.events.ui.ReactWidgetSettings')

export const isPageOOIEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.PageOOI')

export const isPageOOIRegisteredEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.PageOOIRegistered')

export const isWidgetNoProvisionEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.WidgetNoProvision')
