import {IWixAPI} from '@wix/native-components-infra/dist/es/src/types/types'
import {getExternalLink, isRegistrationClosed, isTicketed, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {isEditor, isMobile, isSite} from '../../commons/selectors/environment'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {getUrl} from '../selectors/navigation'
import {parseLocation} from '../utils/navigation'
import {GetState, RouteParams, State, StoreExtraArgs, ThankYouMessageState, ReservationState} from '../types'
import {getReservationId} from '../selectors/reservation'
import {memberRsvpExists} from '../selectors/member-rsvp'
import {shouldNavigateToForm} from '../selectors/rsvp'

export const NAVIGATE = 'NAVIGATE'

export const navigate = (route: DETAILS_ROUTE, routeParams: RouteParams = {}) => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const state = getState()
  const {route: validRoute, routeParams: validRouteParams} = validateRoute(state, route, routeParams)

  dispatch({type: NAVIGATE, payload: {route: validRoute, ...validRouteParams}})

  if (isSite(state)) {
    return wixCodeApi.location.to(getUrl(state, validRoute, validRouteParams))
  }
}

export const validateRoute = (state: State, route: DETAILS_ROUTE, routeParams: RouteParams) => {
  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return {route: validateDetailsRoute(state), routeParams}
    case DETAILS_ROUTE.FORM:
      return {route: validateFormRoute(state), routeParams}
    case DETAILS_ROUTE.TICKET_FORM:
      return {route: validateTicketFormRoute(state), routeParams}
    case DETAILS_ROUTE.CHANGE_RSVP:
      return {route: validateChangeRsvpRoute(state), routeParams}
    case DETAILS_ROUTE.PICK_TICKETS:
      return {route: validateTicketsRoute(state), routeParams}
    case DETAILS_ROUTE.CHECKOUT:
      return {route: validateCheckoutRoute(state), routeParams}
    default:
      return {route, routeParams}
  }
}

const validateDetailsRoute = (state: State) =>
  isEditor(state) || isDetailsPageEnabled(state.siteSettings) ? DETAILS_ROUTE.DETAILS : DETAILS_ROUTE.FORM

const validateTicketFormRoute = (state: State) => {
  if (isSite(state) && !getReservationId(state)) {
    return isMobile(state) ? DETAILS_ROUTE.PICK_TICKETS : DETAILS_ROUTE.DETAILS
  }

  return DETAILS_ROUTE.TICKET_FORM
}

const validateCheckoutRoute = (state: State) => validateTicketFormRoute(state)

const validateFormRoute = (state: State) => {
  const isTicketedEvent = isTicketed(state.event)

  if (isTicketedEvent) {
    return isEditor(state) ? DETAILS_ROUTE.TICKET_FORM : DETAILS_ROUTE.DETAILS
  }

  if (memberRsvpExists(state) && !isTicketedEvent && !shouldNavigateToForm(state)) {
    return DETAILS_ROUTE.CHANGE_RSVP
  }

  return DETAILS_ROUTE.FORM
}

const validateChangeRsvpRoute = (state: State) =>
  isTicketed(state.event) ? DETAILS_ROUTE.DETAILS : DETAILS_ROUTE.CHANGE_RSVP

const validateTicketsRoute = (state: State) => {
  if (isEditor(state) && !isMobile(state)) {
    // Probably opened desktop editor after playing in mobile preview
    return DETAILS_ROUTE.DETAILS
  }

  return DETAILS_ROUTE.PICK_TICKETS
}

export const navigateToDetails = () => navigate(DETAILS_ROUTE.DETAILS)

export const navigateToForm = () => navigate(DETAILS_ROUTE.FORM)

export const navigateToTicketForm = () => navigate(DETAILS_ROUTE.TICKET_FORM)

export const navigateToMobileCheckout = () => navigate(DETAILS_ROUTE.CHECKOUT)

export const navigateToTicketsPicker = () => navigate(DETAILS_ROUTE.PICK_TICKETS)

export const navigateToChangeRsvp = () => navigate(DETAILS_ROUTE.CHANGE_RSVP)

export const navigateToThankYouMessage = (thankYouMessageState: ThankYouMessageState) =>
  navigate(DETAILS_ROUTE.THANK_YOU_MESSAGES, {thankYouMessageState})

export const navigateToOrder = (reservationId: string, reservationState: ReservationState) =>
  navigate(DETAILS_ROUTE.ORDER, {reservationState, reservationId})

export const navigateToMainPage = () => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const siteStructure = await wixCodeApi.site.getSiteStructure()
  const homePage = siteStructure.pages.find(page => page.isHomePage)
  wixCodeApi.location.to(homePage.url)
}

export const handleWithoutRegistrationRedirect = (state: State, dispatch: Function) => {
  if (isSite(state) && isWithoutRegistration(state.event)) {
    return dispatch(navigateToMainPage())
  }
}

export const handleExternalRedirect = (wixCodeApi: IWixAPI, state: State) => {
  const externalLink = getExternalLink(state.event)
  const registrationClosed = isRegistrationClosed(state.event)
  const shouldRedirectIfExternal = isSite(state) && Boolean(externalLink) && !registrationClosed
  const route = parseLocation(wixCodeApi).route
  const redirectingRoutes = [
    DETAILS_ROUTE.CHECKOUT,
    DETAILS_ROUTE.DETAILS,
    DETAILS_ROUTE.FORM,
    DETAILS_ROUTE.PICK_TICKETS,
  ]

  if (redirectingRoutes.includes(route) && shouldRedirectIfExternal) {
    wixCodeApi.location.to(externalLink)
  }
}
