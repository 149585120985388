import {
  getCurrency,
  getEventId,
  isTicketed,
  BI_ENDPOINTS,
  EventMap,
  MembersModal,
  BI_ORIGINS,
  getCouponDiscountId,
  VisitorType,
} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {MODAL_OPENED} from '../../commons/services/modal'
import {
  FORM_PAGE_LOADED,
  DETAILS_PAGE_LOADED,
  TICKETED_THANK_YOU_PAGE_LOADED,
  THANK_YOU_PAGE_LOADED,
} from '../actions/loaded'
import {REGISTRATION_BUTTON_CLICKED} from '../actions/registration'
import {isRestrictedTo} from '../selectors/event'
import {State} from '../types'
import {
  getSelectedTicketsQuantity,
  getTotalOrderRevenue,
  getNonFreeSelectedTicketsCount,
} from '../selectors/selected-tickets'
import {RESERVE_TICKETS} from '../actions/reservation'
import {PLACE_ORDER} from '../actions/placed-order'
import {DOWNLOAD_TICKETS} from '../actions/order-success'
import {SEND_RSVP} from '../actions/rsvp'
import {mapRsvpStatusToBi} from '../utils/api-data-mapper'
import {getTicketQuantity, getGrandTotal, getPaymentMethod, getNonFreeTicketsCount} from '../selectors/placed-order'
import {BI_RSVP_STATUS} from '../constants/rsvp'
import {SHARE_EVENT} from '../actions/event'
import {CLICK_ENTER_COUPON, COUPON_APPLIED} from '../actions/coupon'
import {getInvoice} from '../selectors/invoice'
import {getReservationTicketIds} from '../selectors/reservation'
import {getReservationState} from '../selectors/navigation'

export const eventMap: EventMap = {
  [DETAILS_PAGE_LOADED]: ({event, tickets}: State) => {
    const ticketed = isTicketed(event)

    return {
      evid: 507,
      ticket_definition_count: ticketed ? tickets.length : null,
      currency: ticketed ? getCurrency(event) : null,
      event_id: getEventId(event),
    }
  },
  [RESERVE_TICKETS.REQUEST]: (state: State) => {
    const event = state.event
    return {
      evid: 508,
      ticket_count: getSelectedTicketsQuantity(state.selectedTickets),
      ticket_definition_count: state.tickets.length,
      currency: getCurrency(event),
      event_id: getEventId(event),
      total_order_revenue: getTotalOrderRevenue(state) * 100,
      nonfree_ticket_count: getNonFreeSelectedTicketsCount(state),
      is_member_only_event: isRestrictedTo(state, VisitorType.MEMBER),
    }
  },
  [REGISTRATION_BUTTON_CLICKED]: (state: State) => {
    const event = state.event
    const ticketed = isTicketed(event)

    return {
      evid: 508,
      ticket_count: ticketed ? getSelectedTicketsQuantity(state.selectedTickets) : null,
      ticket_definition_count: ticketed ? state.tickets.length : null,
      currency: ticketed ? getCurrency(event) : null,
      event_id: getEventId(event),
      total_order_revenue: ticketed ? getTotalOrderRevenue(state) * 100 : null,
      nonfree_ticket_count: ticketed ? getNonFreeSelectedTicketsCount(state) : null,
      is_member_only_event: isRestrictedTo(state, VisitorType.MEMBER),
    }
  },
  [FORM_PAGE_LOADED]: (state: State) => {
    const {event, selectedTickets, tickets} = state
    const ticketed = isTicketed(event)

    return {
      evid: 509,
      origin: null,
      event_id: getEventId(event),
      currency: ticketed ? getCurrency(event) : null,
      ticket_count: ticketed ? getSelectedTicketsQuantity(selectedTickets) : null,
      ticket_definition_count: ticketed ? tickets.length : null,
      total_order_revenue: ticketed ? getTotalOrderRevenue(state) * 100 : null,
      nonfree_ticket_count: ticketed ? getNonFreeSelectedTicketsCount(state) : null,
    }
  },
  [PLACE_ORDER.REQUEST]: (state: State) => {
    const {event, tickets, selectedTickets} = state
    const totalOrderRevenue = getTotalOrderRevenue(state) * 100

    return {
      evid: 510,
      button_name: totalOrderRevenue === 0 ? 'submit' : 'choose_payment',
      ticket_count: getSelectedTicketsQuantity(selectedTickets),
      currency: getCurrency(event),
      ticket_definition_count: tickets.length,
      event_id: getEventId(event),
      total_order_revenue: totalOrderRevenue,
      nonfree_ticket_count: getNonFreeSelectedTicketsCount(state),
      visitor_rsvp_count: null,
      rsvp_type: null,
    }
  },
  [DOWNLOAD_TICKETS]: ({event}: State) => ({
    evid: 88,
    event_id: getEventId(event),
    origin: BI_ORIGINS.THANK_YOU_PAGE,
  }),
  [MODAL_OPENED]: ({event}: State, action: AnyAction) => {
    if (action.modalType === MembersModal.LIST) {
      return {
        evid: 89,
        event_id: getEventId(event),
      }
    }
    return null
  },
  [SEND_RSVP.REQUEST]: ({event}: State, action: AnyAction) => {
    const [, formData, status] = action.args
    const totalGuests = 1 + (Number(formData.additionalGuests) || 0)
    const oldStatus = mapRsvpStatusToBi(status)

    return {
      evid: 510,
      button_name: 'submit',
      ticket_count: null,
      currency: null,
      ticket_definition_count: null,
      event_id: getEventId(event),
      total_order_revenue: null,
      nonfree_ticket_count: null,
      visitor_rsvp_count: totalGuests,
      rsvp_type: oldStatus,
    }
  },
  [TICKETED_THANK_YOU_PAGE_LOADED]: (state: State) => {
    const event = state.event
    return {
      evid: 511,
      currency: getCurrency(event),
      ticket_count: getTicketQuantity(state),
      ticket_definition_count: null,
      page_type: getReservationState(state),
      event_id: getEventId(event),
      total_order_revenue: getGrandTotal(state) * 100,
      nonfree_ticket_count: getNonFreeTicketsCount(state),
      paymentmethod: getPaymentMethod(state),
    }
  },
  [THANK_YOU_PAGE_LOADED]: ({event, guest}: State) => ({
    evid: 512,
    event_id: getEventId(event),
    visitor_rsvp_count: guest?.totalGuests,
    rsvp_type: guest?.status ? mapRsvpStatusToBi(Number(guest.status)) : BI_RSVP_STATUS.YES,
  }),
  [SHARE_EVENT]: ({event}: State, action: AnyAction) => ({
    evid: 513,
    event_id: getEventId(event),
    origin: action.payload.origin,
    button_name: action.payload.buttonName,
  }),
  [CLICK_ENTER_COUPON]: ({event}: State) => ({
    evid: 514,
    event_id: getEventId(event),
  }),
  [COUPON_APPLIED]: (state: State) => ({
    evid: 515,
    event_id: getEventId(state.event),
    couponId: getCouponDiscountId(getInvoice(state)),
    ticket_types: getReservationTicketIds(state),
  }),
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}
