import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import Experiments from '@wix/wix-experiments'
import {EXPERIMENTS_SCOPE} from '../constants/app'
import {getBaseUrl} from './wix-code-api'

export const getExperimentsByScope = async (wixCodeApi: IWixAPI, scope: string = EXPERIMENTS_SCOPE) => {
  const baseUrl = getBaseUrl(wixCodeApi) || 'https://www.wix.com'
  const experiments = new Experiments({scope, baseUrl})
  await experiments.ready()
  return experiments.all()
}
